<head>
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossorigin="anonymous">
</head>
<nav class="navbar navbar-white bg-white fixed-top p-0">
  <a class="navbar-brand" href="#">
    <img src="../../../assets/img/logo1.png" height="60" width="60" alt="EPM" class="img-fluid bg-light">
    <span class="text-bold mt-5 h4" style="color:#f71d83;"><span style="color:#0aa2e5">Panthal.</span>com</span>
  </a>
</nav>
<div class="container-fluid p-0"  style="font-family: 'prompt';background-color: rgba(237,252,250,0.78)">
<div class="container-fluid mt-5">
  <div class="row mt-3">
    <div class="row mt-5">
      <div class="col-lg-12 col-12">
        <h5 class="ml-5 text-bold text-capitalize mt-3">{{eventSubName}}</h5>
      </div>
    </div>
  </div>

  <!--<div class="row mt-3 ml-lg-5">
    <h5 class="text-muted mt-2 mb-3">{{eventName}} Another Venues</h5>
    <div class="col-lg-12 col-md-12 col-12">
      <swiper
        [config]="config"
        [spaceBetween]="20"
        [navigation]="true"

      >
        <ng-template swiperSlide *ngFor="let showSubVenue of getEventSubIdDetails">
          <div class="card card-container" type="button" (click)="SubVenueDetails(showSubVenue.epsubid)">
            <img class="card-img-top img-fluid" src="../../../assets/img/mahal1.jpg" alt="Cardcap">
            <div class="card-body">
              <h5 class="text-bold">{{showSubVenue.epsubname}}</h5>
              <p class="text-muted" style="font-size: 14px">{{eventCity}},{{eventState}}</p>
              <h6><span class="text-bold">₹ 400</span><span class="text-muted ml-1" style="font-size: 14px"> Per Day</span></h6>
            </div>
          </div>
        </ng-template>
      </swiper>
    </div>
  </div>-->
</div>

<div class="container-fluid mt-3">
  <div class="row">
    <div class="col-lg-5 col-12 ml-lg-5 ml-0">
      <swiper [slidesPerView]="1" [spaceBetween]="" [navigation]="true">
        <ng-template swiperSlide>
          <img src="https://tomcat3.varnik.cloud:8443/epm-ws/Place/EventsubPlaceLogo/{{mainPlaceId}}/{{subPlaceId}}" class="w-100 img-fluid" style="height: 400px;">
        </ng-template>
        <!--<ng-template swiperSlide><img src="../../../assets/img/mahal1.jpg" class="w-100"></ng-template>
        <ng-template swiperSlide><img src="../../../assets/img/mahal1.jpg" class="w-100"></ng-template>
        <ng-template swiperSlide><img src="../../../assets/img/mahal1.jpg" class="w-100"></ng-template>
        <ng-template swiperSlide><img src="../../../assets/img/mahal1.jpg" class="w-100"></ng-template>
        <ng-template swiperSlide><img src="../../../assets/img/mahal1.jpg" class="w-100"></ng-template>-->
      </swiper>
    </div>
    <div class="col-lg-1 col-12"></div>
    <div class="col-lg-5" *ngIf="showContentOnDesktop">
      <div class="card bg-transparent border rounded p-1">
        <div class="row mt-3 p-2">
          <h5 class="ml-2 text-bold col-12 text-capitalize">About the  {{eventSubName}}</h5>
          <h6 class="ml-3 text-gray font-weight-light">{{eventDescription}}</h6>
        </div>

        <div class="row mt-3 p-2">
          <h5 class="ml-2 text-bold col-12">Address</h5>
          <h6 class="ml-3 text-gray font-weight-light text-capitalize mt-2">{{eventAddressOne}}{{eventAddressTwo}},
            {{eventLandMark}},{{eventCity}} - {{eventPinCode}}</h6>
        </div>
        <hr class="ml-3 mr-3">

        <div class="row mt-3 m-4">
          <div class="col-12">
            <button class="btn btn-success btn-block" style="height: 60px;font-size: 20px" (click)="checkAvailability()">Check Availability</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row mt-3">
    <div class="col-lg-12 col-12">
      <!--<h5 class="text-bold ml-lg-5">Venue Area Details</h5>-->
      <div class="row mt-3 ml-lg-5 ml-2">
        <div class="col-lg-12 col-12">
         <!-- <h5 class="mt-3 ml-2 mb-3 text-capitalize">{{eventSubName}}</h5>-->
          <div class="row mt-3 mb-3" *ngIf="showContentOnMobile">
            <div class="row mt-3 p-2">
              <h5 class="ml-2 text-bold col-12 text-capitalize">About the Venue</h5>
              <h6 class="ml-3 text-gray font-weight-light">{{eventDescription}}</h6>
            </div>

            <div class="row mt-3 p-2">
              <h5 class="ml-2 text-bold col-12">Address</h5>
              <h6 class="ml-3 text-gray font-weight-light text-capitalize mt-2">{{eventAddressOne}}{{eventAddressTwo}},
                {{eventLandMark}},{{eventCity}} - {{eventPinCode}}</h6>
            </div>
          </div>
          <!--<div class="row mt-2 mb-3">
            <div class="col-lg-2 col-12 mt-3 mb-3">
              <img src="../../../assets/icons/wedding%20(2).png" class="img-fluid" style="height: 40px; width: 40px"><span
              class="h5 ml-3 mt-3">Indoor</span>
            </div>
            <div class="col-lg-2 col-12 mt-3 mb-3">
              <img src="../../../assets/icons/waiting%20(2).png" class="img-fluid" style="height: 40px; width: 40px"><span
              class="h5 ml-3 mt-3">400 Seating</span>
            </div>
            <div class="col-lg-2 col-12 mt-3 mb-3">
              <img src="../../../assets/icons/people%20(2).png" class="img-fluid" style="height: 40px; width: 40px"><span
              class="h5 ml-3 mt-3">Floating</span>
            </div>
          </div>-->
        </div>
      </div>
    </div>
  </div>


  <div class="row mt-3 ml-lg-5">
    <div class="col-lg-10 col-md-12 col-12">
      <div class="row mt-5">
        <div class="col-lg-12 col-12">
          <h3 class="text-bold">Venue Area Details</h3>
        </div>
      </div>

      <div class="row mt-3 mb-3">
        <div class="col-lg-6 col-12 mt-lg-0 mt-3">
          <div class="row mt-2">
            <div class="col-lg-2  col-3  col-md-3">
              <img src="../../../assets/icons/air-conditioner.png" class="img-fluid h-50">
            </div>
            <div class="col-lg-10 col-9 col-md-9">
              <div class="row">
                <h4 class="text-bold">Air Conditioner</h4>
              </div>
              <div class="row mt-2">
                <h6 class="text-muted">{{eventAC}}</h6>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-12 mt-lg-0 mt-3">
          <div class="row mt-2">
            <div class="col-lg-2  col-3  col-md-3">
              <img src="../../../assets/icons/kitchen.png" class="img-fluid h-50">
            </div>
            <div class="col-lg-10 col-9 col-md-9">
              <div class="row">
                <h4 class="text-bold">Kitchen</h4>
              </div>
              <div class="row mt-2">
                <h6 class="text-muted">{{kitchenCount}} Kitchen Available</h6>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="row mt-3 mb-3">
        <div class="col-lg-6 col-12 mt-lg-0 mt-3">
          <div class="row mt-2">
            <div class="col-lg-2  col-3  col-md-3">
              <img src="../../../assets/icons/beds.png" class="img-fluid h-50">
            </div>
            <div class="col-lg-10 col-9 col-md-9">
              <div class="row">
                <h4 class="text-bold">Rooms Available</h4>
              </div>
              <div class="row mt-2">
                <h6 class="text-muted">
                  {{totalRooms}} Rooms Available
                  <ul class="mt-3">
                    <li> {{acRoomsCount}} AC Rooms</li>
                    <li> {{nonAcRoomsCount}} Non - AC Rooms</li>
                  </ul>
                </h6>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-12 mt-lg-0 mt-3">
          <div class="row mt-2">
            <div class="col-lg-2  col-3  col-md-3">
              <img src="../../../assets/icons/parking.png" class="img-fluid h-50">
            </div>
            <div class="col-lg-10 col-9 col-md-9">
              <div class="row">
                <h4 class="text-bold">Parking Area</h4>
              </div>
              <div class="row mt-2">
                <h6 class="text-muted">
                  {{parkingCount}} Parking Available
                  <ul class="mt-3">
                    <li> {{biCycleParking}} BiCycles Parking</li>
                    <li> {{bikeParking}} Bike Parking</li>
                    <li> {{carParking}} Car Parking</li>
                    <li> {{truckParking}} Truck, Van parking</li>
                  </ul>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3 mb-3">
        <div class="col-lg-6 col-12 mt-lg-0 mt-3">
          <div class="row mt-2">
            <div class="col-lg-2  col-3  col-md-3">
              <img src="../../../assets/icons/park.png" class="img-fluid h-50">
            </div>
            <div class="col-lg-10 col-9 col-md-9">
              <div class="row">
                <h4 class="text-bold">Open Space Events</h4>
              </div>
              <div class="row mt-2">
                <h6 class="text-muted">
                  <ul class="mt-3" *ngFor="let open of this.openSpaceName">
                    <li>{{open}}</li>
                  </ul>
                </h6>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-12 mt-lg-0 mt-3 mb-5">
          <div class="row mt-2 mb-5">
            <div class="col-lg-2  col-3  col-md-3">
              <img src="../../../assets/icons/mental-health.png" class="img-fluid h-50">
            </div>
            <div class="col-lg-10 col-9 col-md-9">
              <div class="row">
                <h4 class="text-bold">Venue Activities</h4>
              </div>
              <div class="row mt-2">
                <h6 class="text-muted">
                  <ul class="mt-3" *ngFor="let name of this.activityNameList">
                    <li>{{name}}</li>
                  </ul>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  <section class="">
    <div class="container col-lg-10 mt-5">
      <div data-aos="flip-left"
           data-aos-easing="ease-out-cubic" data-aos-duration="3000">
        <div class="row mt-5">
          <h1 class="text-bold text-center mt-5" style="color:#f71d83;"><span style="color:#0aa2e5">Frequently</span>
            Asked Questions
          </h1>
        </div>
        <div class="row mt-5">
          <div class="col-lg-6 col-xs-12 col-sm-12 col-md-6">
            <div class="row mt-5">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item mb-3">
                  <h2 class="accordion-header bg-white accordion-button collapsed" id="headingOne" type="button"
                      data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false"
                      aria-controls="collapseOne">
                    What types of venues can I book through your website?
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                       data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p class="text-muted">
                        Our website offers a diverse range of venues, including event halls, meeting rooms, conference
                        centers, outdoor spaces, and more. You can find venues suitable for various occasions such as
                        weddings, corporate events, parties, and conferences..
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item mb-3">
                  <h2 class="accordion-header bg-white accordion-button collapsed" id="headingTwo" type="button"
                      data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
                      aria-controls="collapseTwo">
                    How do I search for venues on your website?
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                       data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p class="text-muted">Use our search bar to enter specific criteria such as location, event type,
                        capacity, and date. You can also browse through different categories and filters to find the
                        perfect venue for your needs.</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item mb-3">
                  <h2 class="accordion-header bg-white accordion-button collapsed" id="headingThree" type="button"
                      data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false"
                      aria-controls="collapseThree">
                    Is there a fee for using your venue booking service?
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                       data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p class="text-muted">
                        No, our service is free for customers. You can search for venues, compare prices, and contact
                        venue owners without any additional charges.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item mb-3">
                  <h2 class="accordion-header bg-white accordion-button collapsed" type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour"
                      id="headingFour">
                    Can I see the venues before making a booking?
                  </h2>
                  <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                       data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p class="text-muted">
                        Many venues on our platform provide photos, virtual tours, and detailed descriptions. Some
                        venues may also offer the option to schedule a visit. Contact the venue owner directly to
                        inquire about viewing arrangements.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item mb-3">
                  <h2 class="accordion-header bg-white accordion-button collapsed" type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix" id="headingSix">
                    How do I book a venue?
                  </h2>
                  <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix"
                       data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p class="text-muted">
                        Once you find a suitable venue, you can inquire about availability through the website. The
                        venue owner will respond to your request, and you can finalize the booking details directly with
                        them.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item mb-3">
                  <h2 class="accordion-header bg-white accordion-button collapsed" type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive"
                      id="headingFive">
                    What payment methods are accepted for venue bookings?
                  </h2>
                  <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                       data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p class="text-muted">
                        Payment methods vary by venue. Most venues accept credit/debit cards, bank transfers, or other
                        online payment options. Check with the venue owner for specific payment details.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item mb-3">
                  <h2 class="accordion-header bg-white accordion-button collapsed" type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven"
                      id="headingSeven">
                    Is there a cancellation policy for venue bookings?
                  </h2>
                  <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven"
                       data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p class="text-muted">Cancellation policies differ from venue to venue. Be sure to review the
                        terms and conditions provided by the venue owner before finalizing your booking.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item mb-3">
                  <h2 class="accordion-header bg-white accordion-button collapsed" type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight"
                      id="headingEight">
                    Can I make changes to my booking after it's confirmed?
                  </h2>
                  <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight"
                       data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p class="text-muted">Any changes to your booking, such as date or guest count adjustments, should
                        be discussed directly with the venue owner. They will provide guidance on the process and any
                        associated fees.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-12"></div>
          <div class="col-lg-4 col-xs-12 col-sm-12 col-md-4 mt-5">
            <div class="section mt-5" data-aos="fade-right" data-aos-duration="2000">
              <img src="../../../assets/img/faq.png" alt="image" class="img-fluid animated">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

<div class="container-fluid" *ngIf="showContentOnMobile">
  <div class="row col-12">
    <div class="d-flex justify-content-center bg-white align-items-center fixed-bottom"
         style="box-shadow: 1px 4px 4px 1px rgba(0,0,0,0.02) inset;">
      <div class="row">
        <div class="col-12">
          <button class="btn btn-success btn-block mt-3 mb-3" style="height: 60px;font-size: 20px" (click)="checkAvailability()">Check Availability</button>
        </div>
      </div>
    </div>
  </div>
</div>
  <div class="container-fluid p-0">

    <footer class="text-center" style="background-color: rgba(199,233,250,0.73);">
      <!-- Grid container -->
      <!-- <div class="container">
         &lt;!&ndash; Section: Social media &ndash;&gt;
         <section class="mb-4">
           &lt;!&ndash; Facebook &ndash;&gt;
           <a
             class="btn btn-link btn-floating btn-lg text-dark m-1"
             href="#!"
             role="button"
             data-mdb-ripple-color="dark"
           ><i class="fab fa-facebook-f"></i
           ></a>
           <a
             class="btn btn-link btn-floating btn-lg text-dark m-1"
             href="#!"
             role="button"
             data-mdb-ripple-color="dark"
           ><i class="fab fa-linkedin"></i
           ></a>
         </section>
       </div>-->
      <div class="text-center text-bold text-dark p-3" style="background-color: rgba(181,215,234,0.2);">
        <span>Copyright &copy; {{ currentYear }}  </span>
        <a href="https://varnikcloud.com/"
           style="margin: 0; text-decoration: none"
           target="_blank"
        ><span class="text-dark">  VarNik Systems Services Pvt. Ltd</span></a>
        <span>. </span>
        <span>  All rights reserved.</span>
      </div>
      <div class="text-center text-bold" style="background-color: rgba(181,215,234,0.2);">
      <span class="text-black mr-2 text-end" type="button" [routerLink]="['/terms']">Terms & Conditions <span>|</span>
        <span class="text-black ml-1 text-end" type="button" [routerLink]="['/privacy']">Privacy Policy</span></span>
      </div>
    </footer>

  </div>
</div>

<ngx-spinner>
  <div class="custom-loader"></div>
</ngx-spinner>
